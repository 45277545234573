import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditTournament from 'src/components/edit-tournament/edit-tournament';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const EditTournamentPage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/tournaments/edit-tournament">
      <EditTournamentRoute path="/:tournamentId" />
    </Router>
  );
};

interface EditTournamentRouteProps extends RouteComponentProps {
  tournamentId?: string;
}

const EditTournamentRoute: React.FC<EditTournamentRouteProps> = ({ tournamentId }) => {
  return (
    <Layout noPadding>
      <SEO title="Edit Tournament" />
      <EditTournament tournamentId={tournamentId} />
    </Layout>
  );
};

export default EditTournamentPage;
